<app-top-header class="top-bar-container" bgStyle="solid"></app-top-header>

<div class="row justify-content-start align-items-start bg-gray-2">
  <div class="col-auto">
    <div class="side-menu details-header py-2">
      <i (click)="goBack()" class="far fa-angle-left fa-lg cursor-pointer"></i>

      <span class="font-weight-bold font-14 px-2 color-black">
        {{ "Checkout" | translate }}
      </span>
    </div>
  </div>
</div>

<div iknLoader [isActive]="isLoading" class="loader-container"></div>
<ng-container *ngIf="!isLoading">
  <!-- Time and date -->
  <div class="container py-2">
    <h5
      [class.disabled]="
        isShippingMode ||
        !cart.serviceType_ex ||
        cart.serviceType_ex == 'soonest_delivery' ||
        cart.serviceType_ex == 'pickup'
      "
      [routerLink]="['/time-date']"
      class="item-title my-0"
    >
      <span class="font-14 color-black">
        {{ "Time and date" | translate }}
        {{ ":" }}
      </span>
      <span>
        <svg
          style="width: 30px"
          xmlns="http://www.w3.org/2000/svg"
          width="12.695"
          height="14.509"
          viewBox="0 0 12.695 14.509"
        >
          <path
            id="Icon_awesome-calendar-check"
            data-name="Icon awesome-calendar-check"
            d="M12.355,4.534H.34A.34.34,0,0,1,0,4.194V3.174a1.36,1.36,0,0,1,1.36-1.36H2.72V.34A.34.34,0,0,1,3.06,0H4.194a.34.34,0,0,1,.34.34V1.814H8.161V.34A.34.34,0,0,1,8.5,0H9.635a.34.34,0,0,1,.34.34V1.814h1.36a1.36,1.36,0,0,1,1.36,1.36v1.02A.34.34,0,0,1,12.355,4.534ZM.34,5.441H12.355a.34.34,0,0,1,.34.34v7.368a1.36,1.36,0,0,1-1.36,1.36H1.36A1.36,1.36,0,0,1,0,13.148V5.781A.34.34,0,0,1,.34,5.441ZM9.785,8.16l-.8-.8a.34.34,0,0,0-.481,0l-3,2.981L4.2,9.02a.34.34,0,0,0-.481,0l-.8.8a.34.34,0,0,0,0,.481l2.341,2.36a.34.34,0,0,0,.481,0L9.783,8.64a.34.34,0,0,0,0-.481Z"
          />
        </svg>
        <span class="font-13">
          {{ cart?.orderInfo.pickupTime | iknDate : "day" }}
        </span>
        <svg
          style="width: 30px"
          xmlns="http://www.w3.org/2000/svg"
          width="14.509"
          height="14.509"
          viewBox="0 0 14.509 14.509"
        >
          <path
            id="Icon_ionic-ios-time"
            data-name="Icon ionic-ios-time"
            d="M10.629,3.375a7.254,7.254,0,1,0,7.254,7.254A7.253,7.253,0,0,0,10.629,3.375Zm.488,7.882a.49.49,0,0,1-.488.488H7.281a.488.488,0,0,1,0-.977h2.86v-4.6a.488.488,0,1,1,.977,0Z"
            transform="translate(-3.375 -3.375)"
          />
        </svg>
        <span class="font-13">
          {{ cart?.orderInfo.pickupTime | iknDate : "tf" }} -
          {{ cart?.orderInfo.pickupTimeTo | iknDate : "tf" }}
        </span>
      </span>
    </h5>
  </div>
  <div class="container mx-0 px-0 space-between"></div>

  <!-- Area - Service or choose -->

  <div class="container py-3">
    <h5
      [routerLink]="isShippingMode ? [] : ['/service']"
      class="item-title my-0"
    >
      <span class="font-14 color-black" *ngIf="!isShippingMode">
        {{ "Area" | translate }}
        {{ ":" }}
      </span>
      <span class="font-14 color-black" *ngIf="isShippingMode">
        {{ "Location" | translate }}
        {{ ":" }}
      </span>
      <span
        class="font-14 color-black"
        *ngIf="
          (isShippingMode && !cart.orderInfo.pickupAddress.countryId) ||
          (!isShippingMode &&
            (!cart.orderInfo.pickupAddress.areaId || !cart.serviceType_ex))
        "
      >
        {{ "Choose Service - Area" | translate }}
        {{ ":" }}
      </span>
      <span>
        <span
          *ngIf="
            !isShippingMode &&
            cart.orderInfo.pickupAddress.areaId &&
            cart.serviceType_ex
          "
          style="font-size: 13px; color: #656565"
        >
          {{ cart.orderInfo.pickupAddress.areaName | iknLocalized }}
        </span>

        <span
          *ngIf="
            isShippingMode &&
            cart.orderInfo.pickupAddress.countryId &&
            cart.serviceType_ex
          "
          style="font-size: 13px; color: #656565"
        >
          {{ cart.orderInfo.pickupAddress.countryName | iknLocalized }} -
          {{ cart.orderInfo.pickupAddress.governateName | iknLocalized }}
        </span>
      </span>
    </h5>
  </div>
  <div class="container mx-0 px-0 space-between"></div>

  <div *ngIf="cart.serviceType_ex != 'catering'" class="container pt-2 pb-2">
    <!-- <div class="row justify-content-center align-items-center">
      <div class="col-auto">
        <div class="f-boldest t-large">
          <a
            (click)="continueAs = 'guest'"
            class="btn btn-cart page-btn-checkout px-3 d-block btn-min-width font-12"
            >{{ "Continue As Guest" | translate }}</a
          >
        </div>
      </div>
      <div class="col-auto">
        <div class="f-boldest t-large" *ngIf="cart?.cartItems?.length">
          <a
            (click)="continueAs = 'login'"
            class="btn btn-cart page-btn-checkout d-block px-3 btn-min-width font-12"
          >
            {{ "Login" | translate }}</a
          >
        </div>
      </div>
    </div> -->

    <!-- <section class="categories-labels-sec border-bottom-none py-2">
      <div class="container">
        <div class="d-flex">
          <ul class="categories-labels">
            <li (click)="continueAs = 'guest'">
              <a
                class="btn btn-outline-secondary border-sec font-12 color-black font-bold btn-padd"
              >
                {{ "Continue As Guest" | translate }}
              </a>
            </li>
            <li (click)="continueAs = 'login'" *ngIf="cart?.cartItems?.length">
              <a
                class="btn btn-outline-secondary border-sec font-12 color-black font-bold btn-padd"
              >
                {{ "Login" | translate }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </section> -->

    <section
      class="categories-labels-sec py-3 margin-less-15"
      *ngIf="!isLoading"
      [@fadeInOnEnter]
    >
      <div class="container container-padding-5">
        <div class="d-flex">
          <ul class="categories-labels">
            <ng-container *ngFor="let srv of services; let i = index">
              <li class="align-middle">
                <a
                  [ngClass]="{
                    'active-cat':
                      ('srv_' + cart.serviceType_ex | translate) ==
                      ('srv_' + srv | translate)
                  }"
                  (click)="setService(srv)"
                  class="btn btn-outline-secondary px-1"
                >
                  <svg
                    *ngIf="i == 0"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 640 512"
                  >
                    <path
                      fill="#707070"
                      d="M112 0C85.5 0 64 21.5 64 48V96H16c-8.8 0-16 7.2-16 16s7.2 16 16 16H64 272c8.8 0 16 7.2 16 16s-7.2 16-16 16H64 48c-8.8 0-16 7.2-16 16s7.2 16 16 16H64 240c8.8 0 16 7.2 16 16s-7.2 16-16 16H64 16c-8.8 0-16 7.2-16 16s7.2 16 16 16H64 208c8.8 0 16 7.2 16 16s-7.2 16-16 16H64V416c0 53 43 96 96 96s96-43 96-96H384c0 53 43 96 96 96s96-43 96-96h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V288 256 237.3c0-17-6.7-33.3-18.7-45.3L512 114.7c-12-12-28.3-18.7-45.3-18.7H416V48c0-26.5-21.5-48-48-48H112zM544 237.3V256H416V160h50.7L544 237.3zM160 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96zm272 48a48 48 0 1 1 96 0 48 48 0 1 1 -96 0z"
                    />
                  </svg>

                  <svg
                    *ngIf="i == 1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20.149"
                    viewBox="0 0 20.149 20.154"
                  >
                    <g
                      id="Icon_ionic-ios-timer"
                      data-name="Icon ionic-ios-timer"
                      transform="translate(-3.938 -3.938)"
                    >
                      <path
                        id="Path_2306"
                        data-name="Path 2306"
                        d="M14.015,24.092A10.077,10.077,0,0,1,7.021,6.759.809.809,0,0,1,8.145,7.923,8.457,8.457,0,1,0,14.821,5.6V8.749a.811.811,0,1,1-1.622,0v-4a.811.811,0,0,1,.811-.811,10.077,10.077,0,0,1,.005,20.154Z"
                        transform="translate(0)"
                        fill="#707070"
                      />
                      <path
                        fill="#707070"
                        id="Path_2307"
                        data-name="Path 2307"
                        d="M12.052,11.346l5.013,3.6A1.517,1.517,0,1,1,15.3,17.418a1.465,1.465,0,0,1-.353-.353l-3.6-5.013a.505.505,0,0,1,.705-.705Z"
                        transform="translate(-2.073 -2.073)"
                        fill="#707070"
                      />
                    </g>
                  </svg>

                  <svg
                    *ngIf="i == 2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="#707070"
                      d="M320 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM125.7 175.5c9.9-9.9 23.4-15.5 37.5-15.5c1.9 0 3.8 .1 5.6 .3L137.6 254c-9.3 28 1.7 58.8 26.8 74.5l86.2 53.9-25.4 88.8c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l28.7-100.4c5.9-20.6-2.6-42.6-20.7-53.9L238 299l30.9-82.4 5.1 12.3C289 264.7 323.9 288 362.7 288H384c17.7 0 32-14.3 32-32s-14.3-32-32-32H362.7c-12.9 0-24.6-7.8-29.5-19.7l-6.3-15c-14.6-35.1-44.1-61.9-80.5-73.1l-48.7-15c-11.1-3.4-22.7-5.2-34.4-5.2c-31 0-60.8 12.3-82.7 34.3L57.4 153.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l23.1-23.1zM91.2 352H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h69.6c19 0 36.2-11.2 43.9-28.5L157 361.6l-9.5-6c-17.5-10.9-30.5-26.8-37.9-44.9L91.2 352z"
                    />
                  </svg>

                  {{ "srv_" + srv | translate }}
                </a>
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </section>
  </div>

  <!-- Delivery Address   -->
  <div class="container">
    <div *ngIf="continueAs == 'guest'" class="row">
      <div class="radios">
        <div (click)="changeAddressType(1)" class="custom-radio">
          <input
            class="cursor-pointer"
            id="styled-radio-4"
            type="radio"
            [value]="1"
            [checked]="addressType == 1"
          />
          <label
            for="styled-radio-4"
            class="cursor-pointer radio-label-st font-bold font-12"
            >{{ "Enter your delivery address" | translate }}</label
          >
        </div>
      </div>
    </div>
    <div
      *ngIf="showAllAddressTypes == true && continueAs == 'guest'"
      class="d-flex justify-content-between align-items-center py-2"
    >
      <div class="radios">
        <div (click)="changeAddressType(2)" class="custom-radio">
          <input
            class="cursor-pointer"
            id="styled-radio-4"
            type="radio"
            [value]="2"
            [checked]="addressType == 2"
          />
          <label
            for="styled-radio-1 "
            class="cursor-pointer radio-label-st font-bold font-12"
            >{{
              "Send me WhatsApp to get my location (Quick action)" | translate
            }}
          </label>
        </div>
      </div>

      <div class="map-icon-pos">
        <img src="../../assets/images/watsapp-icon-.svg" />
      </div>
    </div>

    <div>
      <app-login-part
        [returnTo]="['/select-address', { returnTo: '/pre-checkout' }]"
        *ngIf="continueAs == 'login'"
      >
      </app-login-part>
      <div class="my-2"></div>
      <app-address-part
        [addressType]="addressType"
        [ngClass]="{ disableForm: addressType != 1 }"
        *ngIf="continueAs == 'guest' && addressType == 1"
        #address
        (areaChanged)="onAreaChanged($event)"
      ></app-address-part>
    </div>
    <!-- Services -->
  </div>

  <div
    *ngIf="showAllAddressTypes == true && continueAs == 'guest'"
    class="container"
  >
    <!-- <div class="d-flex justify-content-between align-items-center py-2">
      <div class="radios">
        <div (click)="changeAddressType(2)" class="custom-radio">
          <input
            class="cursor-pointer"
            id="styled-radio-4"
            type="radio"
            [value]="2"
            [checked]="addressType == 2"
          />
          <label
            for="styled-radio-1 "
            class="cursor-pointer radio-label-st font-bold font-14"
            >{{
              "Send me WhatsApp to get my location (Quick action)" | translate
            }}
          </label>
        </div>
      </div>

      <div class="map-icon-pos">
        <img src="../../assets/images/watsapp-icon-.svg" />
      </div>
    </div> -->
    <!-- WhatsApp Form -->
    <div *ngIf="addressType == 2" class="container">
      <form
        *ngIf="continueAs == 'guest'"
        [ngClass]="{ disableForm: addressType != 2 }"
        #frmAddress="ngForm"
        [formGroup]="addressForm"
      >
        <div class="input-group">
          <div class="input-wrapper input-border-bottom">
            <label class="m-0 font-bold font-14">{{
              "Full Name" | translate
            }}</label>
            <input
              type="text"
              (change)="storeAddress()"
              class=" "
              placeholder="{{ 'Enter' | translate }} {{
                'Full Name' | translate
              }}"
              [(ngModel)]="pickupAddress.contactName"
              formControlName="contactName"
              maxlength="49"
              required
              [ngClass]="{ 'is-invalid': submitted && f.contactName.errors }"
            />

            <div
              *ngIf="submitted && f.contactName.errors && addressType == 2"
              class="invalid-feedback"
            >
              <div *ngIf="f.contactName.errors.required">
                {{ "Name is required" | translate }}
              </div>
            </div>
          </div>

          <div class="input-wrapper input-border-bottom">
            <label class="m-0 font-bold font-14">{{
              "WhatsApp Phone Number" | translate
            }}</label>
            <ngx-intl-tel-input
              [cssClass]="'phone-input'"
              [preferredCountries]="preferredCountries"
              [enableAutoCountrySelect]="false"
              [enablePlaceholder]="true"
              [searchCountryPlaceholder]="'Search Country' | translate"
              [searchCountryFlag]="true"
              [searchCountryField]="[SearchCountryField.All]"
              [selectFirstCountry]="true"
              [selectedCountryISO]="selectedCountryCode"
              [phoneValidation]="true"
              [separateDialCode]="true"
              [(ngModel)]="phoneNumber"
              id="phone-id"
              name="contactPhone"
              formControlName="contactPhone"
              (ngModelChange)="validatePhoneNumber()"
              [ngClass]="{ 'is-invalid': validatePhoneNumber() }"
            ></ngx-intl-tel-input>
            <div
              *ngIf="
                submitted &&
                f.contactPhone.errors &&
                !validatePhoneNumber() &&
                addressType == 2
              "
              class="invalid-feedback"
            >
              <div>
                {{ "Invalid WhatsApp Phone Number" | translate }}
              </div>
            </div>
            <div
              *ngIf="submitted && validatePhoneNumber() && addressType == 2"
              class="invalid-feedback"
            >
              <div *ngIf="f.contactPhone.invalid">
                {{ "Invalid WhatsApp Phone Number" | translate }}
              </div>
            </div>
          </div>
          <div class="input-wrapper input-border-bottom">
            <label class="m-0 font-bold font-14">{{
              "Confirm WhatsApp Phone Number" | translate
            }}</label>
            <ngx-intl-tel-input
              [cssClass]="'phone-input'"
              [preferredCountries]="preferredCountries"
              [enableAutoCountrySelect]="false"
              [enablePlaceholder]="true"
              [searchCountryPlaceholder]="'Search Country' | translate"
              [searchCountryFlag]="true"
              [searchCountryField]="[SearchCountryField.All]"
              [selectFirstCountry]="true"
              [selectedCountryISO]="selectedCountryCode"
              [phoneValidation]="true"
              [separateDialCode]="true"
              [(ngModel)]="cphoneNumber"
              id="cphone-id"
              name="cphoneNumber"
              formControlName="cphoneNumber"
              [ngClass]="{ 'is-invalid': validatePhoneNumber() }"
              (ngModelChange)="validatePhoneNumber()"
            ></ngx-intl-tel-input>
            <div
              *ngIf="submitted && validatePhoneNumber() && addressType == 2"
              class="invalid-feedback"
            >
              {{ "Invalid Confirm WhatsApp Phone Number" | translate }}
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div *ngIf="continueAs == 'guest'" class="container">
    <div class="py-3">
      <div class="row pb-2">
        <h5 class="item-title my-0">
          <span class="color-black font-bold font-14">
            {{ "Choose Payment Method" | translate }}
          </span>
        </h5>
      </div>
      <div *ngIf="paymentMethods?.length">
        <ng-container *ngFor="let pm of paymentMethods">
          <div
            *ngIf="!isShipping || pm.paymentMethod != 'cashOnDelivery'"
            class="radios pb-1"
          >
            <div class="custom-radio">
              <input
                (change)="storePaymentMethod(pm.paymentMethod)"
                class="cursor-pointer"
                id="styled-radio-4{{ pm.paymentMethod }}"
                type="radio"
                [value]="pm.paymentMethod"
                [checked]="paymentMethod == pm.paymentMethod"
                [value]="pm.paymentMethod"
              />
              <label
                for="styled-radio-4{{ pm.paymentMethod }}"
                class="cursor-pointer radio-label-st font-12 font-weight-normal"
                >{{ pm.name | iknLocalized }}
              </label>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div
    *ngIf="!isLoading && !isslotsLoading && continueAs == 'guest'"
    class="container mb-5"
  >
    <div class="row justify-content-center align-items-center mb-5">
      <div class="col-auto">
        <div class="f-boldest t-large" [@slideInUpOnEnter]>
          <a
            *ngIf="addressType == 2"
            (click)="submitOrder()"
            class="btn btn-cart page-btn-checkout px-4"
          >
            <span *ngIf="isOpen !== undefined">
              <span
                class="font-weight-bold"
                *ngIf="isOpen || cart.orderInfo.pickupTime"
                >{{ "Complete the request" | translate }}</span
              >
              <span
                class="font-weight-bold"
                *ngIf="isOpen === false && !cart.orderInfo.pickupTime"
              >
                {{ "submit_closed" | translate }}
                {{ "operating_hours_warning" | translate : operatingHours }}
              </span>
            </span>
          </a>

          <a
            *ngIf="addressType != 2"
            (click)="
              cmpAddress.saveAddress(cart.orderInfo.pickupAddress) &&
                submitOrder()
            "
            class="btn btn-cart page-btn-checkout px-4"
          >
            <span *ngIf="isOpen !== undefined">
              <span
                class="font-weight-bold"
                *ngIf="isOpen || cart.orderInfo.pickupTime"
                >{{ "Complete the request" | translate }}</span
              >
              <span
                class="font-weight-bold"
                *ngIf="isOpen === false && !cart.orderInfo.pickupTime"
              >
                {{ "submit_closed" | translate }}
                {{ "operating_hours_warning" | translate : operatingHours }}
              </span>
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ngx-smart-modal
  #mconfirmPhonenumber
  identifier="mphoneValidator"
  [dismissable]="false"
>
  <div class="modal-header"></div>
  <div class="modal-body">
    <div class="popap">
      <div
        class="alert alert-danger"
        role="alert"
        *ngIf="!isverificationCodeValid"
      >
        {{ "Verification code not valid" | translate }}
      </div>
      <br />
      <br />
      <h3>{{ "OTP Verification" | translate }}</h3>
      <p>
        {{ "Please enter the verification code sent" | translate }} <br />{{
          "To" | translate
        }}
        {{ phoneNumber?.internationalNumber }}
      </p>
      <span id="timer">{{ recordingTimer }}</span
      ><br />
      <div class="codeinputcontainer">
        <input
          class="form-control codeinput"
          type="text"
          name="code"
          required
          (ngModelChange)="changeCode()"
          [(ngModel)]="verificationCode"
        />
      </div>
      <p>
        {{ "Didnt receive OTP" | translate }}
        <a class="resend" (click)="sendVerificationCode()"
          >{{ "Resend OTP" | translate }}
        </a>
      </p>
      <button class="verifycodebtn" (click)="verifyThePhoneCode()">
        {{ "Verify" | translate }}
      </button>
    </div>
  </div>
  <div class="modal-footer"></div>
</ngx-smart-modal>
