import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { CartExtraItem } from "./models";

declare let ga: Function;
declare let gtag: Function;
declare let dataLayer;

@Injectable({
  providedIn: "root",
})
export class GoogleTagManagerService {
  currencyCode = environment.currency_code || "KWD";

  constructor() {}
  eventEmitter(
    eventCategory: string,
    eventAction: string,
    eventLabel: string = "",
    eventValue: number = 1
  ) {
    gtag("send", "event", {
      eventCategory: eventCategory,
      eventAction: eventAction,
      eventLabel: eventLabel,
      eventValue: eventValue,
    });
  }

  priceEventEmitter(
    transactionId: string,
    orderValue: number,
    orderCurrency: string
  ) {
    if (typeof gtag !== "undefined") {
      gtag("ecommerce:addTransaction", {
        id: transactionId,
        revenue: orderValue,
        currency: orderCurrency,
      });

      gtag("ecommerce:send");
    }
  }

  //Active
  viewItem(item) {
    if (typeof gtag !== "undefined" && typeof dataLayer !== "undefined") {
      let product = {
        name: item.name, // Name or ID is required.
        id: item.id,
        price: item.price,
        brand: item.brand,
        category: item.category,
        variant: item.variant,
      };
      gtag("ec:addProduct", {
        name: item.name, // Name or ID is required.
        id: item.id,
        price: item.price,
        brand: item.brand,
        category: item.category,
        variant: item.variant,
      });
      gtag("ec:setAction", "detail");
      gtag("send", "pageview");

      dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      dataLayer.push({
        ecommerce: {
          detail: {
            actionField: { list: "product-clicked" }, // 'detail' actions have an optional list property.
            products: [product],
          },
        },
      });
      dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
      dataLayer.push({
        event: "productClick",
        ecommerce: {
          click: {
            actionField: { list: "Search Results" }, // Optional list property.
            products: [product],
          },
        },
      });
    }
  }

  //Active
  pageView(e) {
    if (typeof gtag !== "undefined") {
      gtag("send", "pageview", e.urlAfterRedirects);
    }
  }

  addItemEventEmitter({
    id,
    name,
    category,
    price,
    quantity,
  }: {
    id: string;
    name: string;
    category: string;
    price: number;
    quantity: number;
  }) {
    if (typeof gtag !== "undefined") {
      gtag("ecommerce:addItem", {
        id: id,
        name: name,
        category: category,
        price: price,
        quantity: quantity,
      });

      gtag("ecommerce:send");
    }
  }

  createProductObject(products, listType) {
    let gaProduct = [];
    let gtmProduct = [];
    let i = 0;
    products.forEach((product) => {
      i++;
      let price = 0;

      if (
        product["variants"] &&
        product["variants"].length > 0 &&
        product["variants"][0].price
      ) {
        price = product["variants"][0].price;
      } else {
        if (product.combinationPrice) {
          price = product.combinationPrice.price;
        }
      }
      gaProduct.push({
        id: product.slug,
        name: product.name,
        list_name: listType,
        brand: "Broker",
        category: product.mainTaxon
          ? product.mainTaxon.name
          : product.main_taxon,
        variant: "",
        list_position: i,
        quantity: 1,
        price: price,
      });
      gtmProduct.push({
        name: product.name,
        id: product.slug,
        price: price,
        brand: "Broker",
        category: product.mainTaxon
          ? product.mainTaxon.name
          : product.main_taxon,
        variant: "",
        list: listType,
        position: i,
      });
    });

    if (typeof gtag !== "undefined" && typeof dataLayer !== "undefined") {
      gtag("event", "view_item_list", {
        items: gaProduct,
      });
      dataLayer.push({ ecommerce: null });
      dataLayer.push({
        ecommerce: {
          currencyCode: this.currencyCode,
          impressions: gtmProduct,
        },
      });
    }
  }

  addToCartEventEmitter(item, type, extra: Array<CartExtraItem> = []) {
    let product = item;

    let inital = 0;
    if (extra.length > 0) {
      for (let index = 0; index < extra.length; index++) {
        const element = extra[index];
        console.log(element);
        console.log(element.$extraItem?.price);
        inital += element.$extraItem?.price;
      }
      product.price += inital;
    }
    console.log(product);
    if (typeof gtag !== "undefined" && typeof dataLayer !== "undefined") {
      if (type === "add") {
        gtag("event", "add_to_cart", {
          currency: product.ChannelCurrency,
          items: [
            {
              id: product.id,
              name: product.name,
              brand: product.brand,
              category: product.category,
              quantity: product.quantity,
              price: product.price,
            },
          ],
        });

        dataLayer.push({ ecommerce: null });
        dataLayer.push({
          event: "addToCart",
          ecommerce: {
            currencyCode: this.currencyCode,
            add: {
              products: [
                {
                  id: product.id,
                  name: product.name,
                  list_name: "Products",
                  brand: product.brand,
                  category: product.category,

                  quantity: product.quantity,
                  price: product.price,
                },
              ],
            },
          },
        });
      } else {
        gtag("event", "remove_from_cart", {
          items: [
            {
              id: product.id,
              name: product.name,
              brand: product.brand,
              category: product.category,
              quantity: product.quantity,
              price: product.price,
            },
          ],
        });

        dataLayer.push({ ecommerce: null });
        dataLayer.push({
          event: "removeFromCart",
          ecommerce: {
            remove: {
              products: [
                {
                  id: product.id,
                  name: product.name,
                  brand: product.brand,
                  category: product.category,
                  quantity: product.quantity,
                  price: product.price,
                },
              ],
            },
          },
        });
      }
    }
  }

  checkextraPrice(extra: Array<CartExtraItem> = []): number {
    let inital = 0;
    if (extra.length > 0) {
      for (let index = 0; index < extra.length; index++) {
        const element = extra[index];
        console.log(element);
        console.log(element.$extraItem?.price);
        inital += element.$extraItem?.price;
      }
    }
    return inital;
  }
  checkoutEventEmitter(products) {
    let gaProduct = [];
    let gtmProduct = [];

    for (let index = 0; index < products.length; index++) {
      const element = products[index];
      element.price += this.checkextraPrice(element.extras);
      gaProduct.push({
        name: element.name,
        id: element.id,
        price: element.price,
        brand: element.brand,
        category: element.category,
        quantity: element.quantity,
      });
      gtmProduct.push({
        name: element.name,
        id: element.id,
        price: element.price,
        brand: element.brand,
        category: element.category,
        quantity: element.quantity,
      });
    }

    console.log(gtmProduct);
    if (typeof gtag !== "undefined" && typeof dataLayer !== "undefined") {
      gtag("event", "begin_checkout", {
        items: gaProduct,
        coupon: localStorage.getItem("currentCoupon") || "",
      });

      dataLayer.push({ ecommerce: null });
      dataLayer.push({
        event: "checkout",
        ecommerce: {
          checkout: {
            actionField: { step: 1, option: "" },
            products: gtmProduct,
          },
        },
      });
    }
  }

  purchaseEventEmitter(
    products,
    orderNumber,
    orderValue,
    orderCurrency,
    paymentMenthod
  ) {
    let gaProduct = [];
    let gtmProduct = [];
    let i = 0;
    products.forEach((product) => {
      i++;
      gaProduct.push({
        id: product.id,
        name: product.name,
        brand: product.brand,
        quantity: product.quantity,
        price: product.price,
      });
      gtmProduct.push({
        id: product.id,
        name: product.name,
        brand: product.brand,
        quantity: product.quantity,
        price: product.price,
      });
    });
    if (typeof gtag !== "undefined" && typeof dataLayer !== "undefined") {
      gtag("event", "purchase", {
        transaction_id: orderNumber,
        affiliation: "Broker Store",
        value: orderValue,
        currency: orderCurrency,
        tax: 0,
        shipping: 0,
        items: gaProduct,
      });

      dataLayer.push({ ecommerce: null });
      dataLayer.push({
        ecommerce: {
          purchase: {
            actionField: {
              id: orderNumber,
              affiliation: "Online Store",
              revenue: orderValue,
              tax: "0",
              shipping: "0",
              coupon: localStorage.getItem("currentCoupon") || "",
            },
            products: gtmProduct,
          },
        },
      });
    }
  }
}
